
import { defineStore } from 'pinia'

export const useDD = defineStore('DealDesk', {
	state: () => ({
		percents: false,
		audienceCount: null,
		filters: {
			country_name: [],
			industry_name: [],
			n_of_employees_group: [],
			revenue_group: [],
			job_function_name: [],
			job_level_group: []
		},
		selected: {
			country_name: [],
			industry_name: [],
			n_of_employees_group: [],
			revenue_group: [],
			job_function_name: [],
			job_level_group: ['C-Level', 'Directors', 'Managers', 'Vice Presidents']
		},
		acr: null,
		firstCheckID: null,
		DD: [],
		reportSetup: null,
		draftID: null,
		reloadDraft: false,
		type: null,
		headers: {
			company: {
				show: false,
				value: {}
			},
			sales_person: {
				show: false,
				value: {}
			},
			start_end_date: {
				show: false,
				value: {}
			}
		},
		exportPDF: false,
		recommendations: null
	}),
	actions: {
		resetDD(){
			this.$reset();
		},
		addPercents (data) {
			this.percents = data
		},
		setAudience(data){
			this.audienceCount = data
		},
		setFilters(key, data){
			this.filters[key] = data;
		},
		addSelected(data){
			this.selected = data;
		},
		updateACR(data){
			this.acr = data;
		},
		updateFirstCheck(data){
			this.firstCheckID = data;
		},
		setDD(data){
			this.DD = data;
		},
		setReportSetup(data){
			this.reportSetup = data;
		},
		setFromData(data){
			const keys = Object.keys(this.$state);
			keys.forEach(key => {
				if (key in data.jsonData) {
					this[key] = data.jsonData[key]
				}
			})
		},
		setDraftID(data){
			this.draftID = data
		},
		updateReloadState(state){
			this.reloadDraft = state;
		},
		setType(type){
			this.type = type;
		},
		exportReport(state){
			this.exportPDF = state;
		},
		setRecommendations(data){
			this.recommendations = data;
		}
	}
})
